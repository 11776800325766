<template>
  <div class="ModifyClientAccessPassword">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CModal
      :show.sync="isClientAccessPasswordModified"
      :no-close-on-backdrop="true"
      title="Mot de passe client modifié"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Le mot de passe de votre client a bien été modifié 👍
      </p>
      <p>
        Attention à bien prévenir votre client de ces modifications.
      </p>
      <template #footer>
        <CButton @click="$router.push('/client-details/' + client.id)" color="primary">Retour au profil client</CButton>
        <CButton @click="isClientAccessPasswordModified = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>

    <CRow>
      <CCol sm="6">
        <h1> Modifer le mot de passe de {{client.name}} </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="goBackToClientProfile"
          size="sm"
          shape="pill"
          color="outline-dark">
            <CIcon name="cil-arrow-left"/> Retour au profil client
        </CButton>
      </CCol>
    </CRow>

    <CCard v-if="client.user" class="mt-3">
      <CCardBody>
        <CRow>
          <CCol>
            <p>
              <strong>Rappel</strong> : Le nom d'utilisateur Eclerk de votre client est <strong>{{client.user.username}}</strong>
            </p>
          </CCol>
        </CRow>
        <CForm v-on:submit.prevent="modifyClientAccessPassword()">
          <CInput
            label="Nouveau mot de passe"
            placeholder="********"
            type="password"
            maxlength="249"
            v-model="newPassword"
            @input="$v.newPassword.$touch()"
            :isValid="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
            invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
          >
          </CInput>

          <CInput
            label="Confirmation du nouveau mot de passe"
            placeholder="********"
            type="password"
            maxlength="249"
            v-model="newPasswordConfirmation"
            @input="$v.newPasswordConfirmation.$touch()"
            :isValid="$v.newPasswordConfirmation.$dirty ? !$v.newPasswordConfirmation.$error : null"
            invalid-feedback="Veuillez rentrer deux mots de passe identiques"
          >
          </CInput>

          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" color="outline-primary"
                shape="pill" block class="px-4"
                :disabled="$v.newPasswordConfirmation.$invalid || modifyPasswordInProcess">
                Modifier le mot de passe de votre client
              </CButton>
            </CCol>
          </CRow>
        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { required, maxLength, sameAs } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
import { password_validation } from '@/validators/validators'

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'ModifyClientAccessPassword',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {

      // ---- CLIENT ---------
      isClientLoading: false,
      client: {
        address: '',
        city: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
        user: null
      },

      newPassword: '',
      newPasswordConfirmation: '',
      modifyPasswordInProcess: false,

      isClientAccessPasswordModified: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading || this.modifyPasswordInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    newPassword: {
      maxLength: maxLength(249),
      required,
      password_validation
    },
    newPasswordConfirmation: {
      maxLength: maxLength(249),
      required,
      sameAsPassword: sameAs('newPassword'),
    }
  },

  created: function() {
    this.getClient()
  },


  methods: {
    // ------------- Getters --------------
    getClient () {
      this.isClientLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.id)
      .then((result) => {
        this.client = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isClientLoading = false
      })
    },


    // ---------- Modify access client -----------
    modifyClientAccessPassword () {
      this.modifyPasswordInProcess = true
      apiManagerConnected.modifyClientAccessPassword(
        this.token, this.client.id, this.newPassword, this.newPasswordConfirmation)
      .then(() => {
        this.isClientAccessPasswordModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyPasswordInProcess = false
      })
    },


    // --- Router functions
    goBackToClientProfile () {
      this.$router.push('/client-details/' + this.client.id)
    }

  }
}
</script>
